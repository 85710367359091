import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ContactUs() {
    return (
        <>
            <Header />
            <div className="contactbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Contact Us</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                   <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactus-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 contactus-img col-md-12">
                            <div className="contactleft-info">
                                <img src="img/contact.jpg" className="img-fluid" alt/>
                                    <div className="contactinfo-content">
                                        <div className="contact-hours">
                                            <h6>Working Hours</h6>
                                            <ul>
                                                <li>Monday - Saturday : 10:00am - 06:00pm</li>
                                                <li>Closed on Sunday</li>
                                            </ul>
                                        </div>
                                        <div className="contact-hours">
                                            <h6>Contact Us</h6>
                                            <h5>Inpack Solutions India</h5>
                                            <ul>
                                                <li>B-06, Logix Technova, Sector -132, Noida, India - 201304</li>
                                                {/* <li>Tel : +91 - 0120 4354214</li> */}
                                                <li>Mobile / WhatsApp - +91 - 9811226914</li>
                                                {/* <li> Email : support@printingmachinedeal.com</li> */}
                                                <li>Email : bhupendra@inpack.in</li>
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6 contactright-map col-md-12">
                            <div className="google-maps">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14024.150579641424!2d77.3793736!3d28.5085151!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4f230000001%3A0xb117407c08ce8f94!2sInpack%20Solution%20India!5e0!3m2!1sen!2sin!4v1694682749243!5m2!1sen!2sin" width="600" height="544" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="contactusform-section">
                <div className="container">
                    <div className="contact-info">
                        <h2>Contact <span>Us</span></h2>
                        <p>We are here to help you</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5">
                            <div className="contactform-img">
                                <img src="assets/img/contactform-img.svg" className="img-fluid" alt />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="contactus-form">
                                <form class>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name*" required />
                                    </div>
                                    <div className="form-group me-0">
                                        <input type="email" className="form-control" placeholder="Email*" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Mobile*" />
                                    </div>
                                    <div className="form-group">
                                        <textarea rows="4" className="form-control" placeholder="Write a Message*" required></textarea>
                                    </div>
                                    <div className="submit-section">
                                        <button className="btn btn-primary submit-btn" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default ContactUs;