import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import hostname from "../config";
import moment from 'moment';
import Header from './include/Header';
import Footer from './include/Footer';

function Search() {
    const history = useHistory();
    // States
    const [dataItems, setData] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [catExists, setCateExists] = useState([]);
    const [categoryName, setCategoryName] = useState([]);
    

    //States for categorya and sub category:
    const [CategoryId, setCategoryId] = useState([]);
    const [SubCategoryId, setSubCategoryId] = useState([]);
    const [contetLoading, setContentLoading] = useState("Loading...");

    const categoryUrl = `${hostname}/api/category/getCategoryList`;
    const subCategoryUrl = `${hostname}/api/category/getSubCategoryList`;

    const getCategoryInfo =  async () => {
        return await axios.get(categoryUrl).then((res) => setCategory(res.data.category));
    };

    const getSubCategory = async (event) => {
        const value = event.target.value;
        setCategoryId(value);
        return await axios.get(subCategoryUrl+'?cat_id='+value).then((res) => setSubCategory(res.data.sub_category));
    }

    const search = async (event) => {
        event.preventDefault();
        history.push('/search?cat='+CategoryId+'&sub_cat='+SubCategoryId);  
        fetchInfo(CategoryId, SubCategoryId);  
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cat_id = queryParams.get('cat');
    const subcat_id = queryParams.get('sub_cat');

    const filderByCategory = () => {
        if (!queryParams.has('sub_cat')) {
            setCateExists(true);
        } else {
            setCateExists(false)
        }
    }

    // Fetch Data
    const fetchInfo = (cat_id, subcat_id) => {
        axios.post(`${hostname}/api/product/search?cat=${cat_id}&subcat=${subcat_id}`)
            .then((response) => {
                if(response.status == 200) {
                    if(response.data.total_count > 0) {
                       //console.log(response.data.results[0].category_name);
                       setCategoryName(response.data.results[0].category_name);
                    }
                     setData(response.data.results)
                    setTotalCount(response.data.total_count);
                    setContentLoading("No Results Found.")
                }
            })
            .catch((error) => {
                console.error('Error inserting data:', error);
            });
    };
    useEffect(() => {
        fetchInfo(cat_id, subcat_id);
        getCategoryInfo();
        filderByCategory();
    }, []);
  
    return (
        <>
        <Header />
        
        <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                {catExists == true ? (
                                    <h2 className="breadcrumb-title">{categoryName}</h2>
                                ) : (
                                    <h2 className="breadcrumb-title">Search All Machine</h2>
                                )}
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        {catExists == true ? (
                                            <li className="breadcrumb-item active">{categoryName}</li>
                                        ) : (
                                            <li className="breadcrumb-item active">Search All Machine</li>
                                        )}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 theiaStickySidebar">
                            <div className="listings-sidebar">
                                <div className="card">
                                    <h4><img src="assets/img/details-icon.svg" alt="details-icon"/> Filter</h4>
                                    <form onSubmit={search}>
                                        <div className="filter-content form-group">
                                            <select className="form-control select category-select" onChange={getSubCategory} required>
                                                <option value="">Choose Category</option>                                                        
                                                {category.map((item, key) => {
                                                    return (<option value={item.id}>{item.category_name}</option>)
                                                })}
                                            </select>
                                        </div>

                                        <div className="filter-content form-group">
                                            <select className="form-control select category-select" onChange={(event) => {setSubCategoryId(event.target.value)}} required>
                                                    <option value="">Choose Sub Category</option>
                                                    {subCategory.map((item, key) => {
                                                        return (<option value={item.id}>{item.category_name}</option>)
                                                    })}
                                            </select>
                                        </div>

                                        <div className="filter-content amenities mb-0">
                                            <div className="search-btn">
                                                <button className="btn btn-primary" type="submit"> <i className="fa fa-search"
                                                    aria-hidden="true"></i> Search</button>
                                                <button className="btn btn-reset mb-0" type="submit"> <i
                                                    className="fas fa-light fa-arrow-rotate-right"></i> Reset Filters</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row sorting-div">
                                <div className="col-lg-4 col-md-4 col-sm-4 align-items-center d-flex">
                                    <div className="count-search">
                                        <p>Showing <span>1-8</span> of {totalCount} Results</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8  align-items-center">
                                    <div className="sortbyset">
                                        <span className="sortbytitle">Sort by</span>
                                        <div className="sorting-select">
                                            <select className="form-control select">
                                                <option>Default</option>
                                                <option>Price Low to High</option>
                                                <option>Price High to Low</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="grid-listview">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="feather-list"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="active">
                                                      <i className="feather-grid"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-view listgrid-sidebar">
                            {dataItems.length > 0 ? (
                                <div className="row">                                   
                                  { dataItems.map((item, key) => {
                                    return (<div className="col-lg-6 col-md-4">
                                        <div className="card">
                                            <div className="blog-widget">
                                                <div className="blog-img">
                                                {item.type === 'new' ? (
                                                    <Link to={`/new-machine-details?id=${item.id}`}>
                                                            <img src={item.image_url} className="img-fluid" alt="blog-img"/>
                                                    </Link>
                                                ) : (
                                                    <Link to={`/old-machine-details?id=${item.id}`}>
                                                      <img src={item.image_url} className="img-fluid" alt="blog-img"/>
                                                   </Link> 
                                                )}
                                                </div>
                                                <div className="bloglist-content">
                                                    <div className="card-body">
                                                        <div className="blogfeaturelink">
                                                        </div>
                                                        <h6>
                                                            {item.type === 'new' ? (
                                                                <Link to={`/new-machine-details?id=${item.id}`}>
                                                                        {item.machine_name}
                                                                </Link>
                                                            ) : (
                                                                <Link to={`/old-machine-details?id=${item.id}`}>
                                                                    {item.machine_name}
                                                                </Link> 
                                                            )}
                                                        </h6>
                                                        <div className="blog-location-details">
                                                            <div className="location-info">
                                                               {item.type == 'new' ? (
                                                                    <span><i className="feather-map-pin"></i> New Machine</span>
                                                                ) : (
                                                                    <span><i className="feather-map-pin"></i> Used Machine</span>
                                                                )}
                                                            </div>
                                                            <div className="location-info">
                                                                <i className="fa-solid fa-calendar-days"></i> { moment(item.created_at).format('DD/MM/YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     </div>)
                                    })}
                                </div>
                                ) : (
                                    <p>{contetLoading}</p>
                                )}
                            </div>

                            <div className="blog-pagination">
                                <nav>

                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        <Footer />
        </>
    );
}
export default Search;