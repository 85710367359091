import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Management() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Management Team</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Management Team</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container"><br></br><br></br>
                    <p><strong>Printing Machine Deal Management Team</strong></p>
                    <p>At Printing Machine Deal, our success is driven by a dedicated and experienced management team that is committed to providing a top-notch platform for buyers and sellers in the printing and packaging machinery industry. Our team comprises professionals with diverse expertise and a shared passion for facilitating seamless transactions within the industry.</p>
                    <p><strong>Our Commitment to Excellence</strong></p>
                    <p>The management team at Printing Machine Deal is driven by a shared commitment to excellence, innovation, and user satisfaction. We continuously strive to enhance our platform's features, security measures, and customer support to create a trustworthy and efficient marketplace for all printing and packaging machinery needs.</p>
                    <p>Our vision is to remain at the forefront of the industry, fostering connections, and facilitating successful transactions. We appreciate the trust that our users place in us and are dedicated to providing the highest level of service to meet their needs.</p>
                    <p>If you have any questions, feedback, or require assistance, please don't hesitate to reach out to our team. We're here to serve you and make your experience on Printing Machine Deal as seamless and rewarding as possible. Thank you for choosing us as your partner in the printing and packaging machinery industry.</p>
                </div>
            </section>

          
            <Footer />
        </>
    );
}
export default Management;
