import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home/Home';
import BuyUsedMachine from './components/BuyUsedMachine';
import BuyNewMachine from './components/BuyNewMachine';
import NewMachineDetails from './components/NewMachineDetails';
import OldMachineDetails from './components/OldMachineDetails';
import Login  from "./components/Login";
import Register from "./components/Register";
import ContactUs from "./components/ContactUs";
import ForgotPassword from './components/ForgotPassword';
import AboutUs from './components/AboutUs';
import Search from './components/Search';
import Management from './components/Management';
import Careers from './components/Careers';
import Disclaimer from './components/Disclaimer';
import Accolades from './components/Accolades';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndCondition from './components/TersmAndCondtion';
import RefundPolicy from './components/RefundPolicy';
import Resources from './components/Resources';
import Profile from './components/Profile';
import BuyAccessories from './components/BuyAccessories';
import AccessoriesDetails from './components/AccessoriesDetails';

function App() {
  return (
    <>
      <Router>
            <Route exact path="/" component={Home} />
            <Route path="/buy-use-machine" component={BuyUsedMachine} />
            <Route path="/buy-new-machine" component={BuyNewMachine} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/about-us" component={AboutUs}/>
            <Route path="/new-machine-details" component={NewMachineDetails}/>
            <Route path="/old-machine-details" component={OldMachineDetails}/>
            <Route path="/search" component={Search}/>
            <Route path="/management" component={Management}/>
            <Route path="/careers" component={Careers}/>
            <Route path="/disclaimer" component={Disclaimer}/>
            <Route path="/accolades" component={Accolades}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/terms-conditions" component={TermsAndCondition}/>
            <Route path="/refund-policy" component={RefundPolicy}/>
            <Route path="/resources" component={Resources}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/buy-accessories" component={BuyAccessories}/>
            <Route path="/accessories-details" component={AccessoriesDetails}/>

      </Router>
   
     {/* <div className="main-wrapper">  
          <Header />
          <Home/>
          <Footer/>
      </div> */}
    </> 
  );
}

export default App;
