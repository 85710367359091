import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import axios from "axios";
import hostname from "../config";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Header from './include/Header';
import Footer from './include/Footer';

function Login() {
    
    const history = useHistory();
    console.log(history);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formDataObject = new FormData();
        formDataObject.append('email', formData.email);
        formDataObject.append('password', formData.password);
        console.log(Object.fromEntries(formDataObject.entries()))
        try {
            const response = await axios.post(`${hostname}/api/login`,formDataObject).then((response) => {
                console.log("response:::", response)
                if(response.data.status == 201) {
                    toast.error(response.data.message);
                }
                if(response.data.status == 200){
                   localStorage.setItem('name', response.data.name);
                   localStorage.setItem('userId', response.data.loginId);
                    toast.success(response.data.message);
                    setTimeout(()=>{
                        history.push("/")           
                    },1000)
               } 
            })
            .catch((error) => {
                console.error('Error inserting data:', error);
            });
        } catch(error) { }
    };

    return (
        <>
        <Header/>
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">Login</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                               <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" >Login</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <div className="login-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 mx-auto">
                            <div className="login-wrap">
                                <div className="login-header">
                                    <h3>Welcome Back</h3>
                                    <p>Please Enter your Details</p>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="form-group group-img">
                                        <div className="group-img">
                                            <i className="feather-mail"></i>
                                            <input type="text" name="email" className="form-control" placeholder="Email Address" required onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="pass-group group-img">
                                            <i className="feather-lock"></i>
                                            <input type="password" name='password' className="form-control pass-input" required placeholder="Password" onChange={handleInputChange}/>
                                                <span className="toggle-password feather-eye"></span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <label className="custom_check">
                                                <input type="checkbox" name="rememberme" className="rememberme"/>
                                                    <span className="checkmark"></span>Remember Me
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="text-md-end">
                                                <Link className="forgot-link" to="/forgotpassword">Forgot password?</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Sign in</button>
                                    <div className="register-link text-center">
                                        <p>No account yet? <Link className="forgot-link" to="/register">Signup</Link></p>
                                    </div>
                                    {/* <div className="login-or">
                                        <span className="or-line"></span>
                                        <span className="span-or">Sign in with Social Media Accounts</span>
                                    </div>
                                    <div className="social-login">
                                        <a href="#" className="btn btn-apple w-100"><img src="assets/img/apple.svg" className="me-1" alt="img"/>Sign in with Apple</a>
                                    </div>
                                    <div className="social-login">
                                        <a href="#" className="btn btn-google w-100"><img src="assets/img/google.svg" className="me-1" alt="img"/>Sign in with Google</a>
                                    </div>
                                    <div className="social-login">
                                        <a href="#" className="btn btn-facebook w-100 mb-0"><img src="assets/img/facebook.svg" className="me-2" alt="img"/>Continue with Facebook</a>
                                    </div> */}
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark"/>
            </div>
        <Footer/>
        </>
    );
}
export default Login;
