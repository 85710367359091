import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Accolades() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Accolades</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Accolades</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container">
                    <br></br>
                    <br></br>
                    <p><strong>Our Accolades</strong></p>
                    <p><strong>1. Industry Leader:</strong> Printing Machine Deal has established itself as a leader in the printing and packaging machinery marketplace, connecting buyers and sellers worldwide.</p>
                    <p><strong>2. Trusted Platform:</strong> We are proud to be recognized as a trusted and reliable platform, known for facilitating secure and transparent transactions.</p>
                    <p><strong>3. User Satisfaction:</strong> Our dedication to customer satisfaction has earned us accolades from satisfied buyers and sellers who have experienced the ease and efficiency of our platform.</p>
                    <p><strong>4. International Reach:</strong> Printing Machine Deal has been commended for its global reach, connecting businesses and individuals across borders to access a diverse range of machinery options.</p>
                    <p><strong>5. Innovative Solutions:</strong> We have received recognition for our innovative solutions in simplifying the buying and selling process within the printing and packaging machinery industry.</p>
                    <p><strong>6. Customer Support Excellence:</strong> Our commitment to outstanding customer support has been acknowledged by users who have praised our responsive and helpful team.</p>
                    <p><strong>7. Security and Privacy:</strong> We have received accolades for our robust security measures and privacy practices, ensuring the safety of user data and transactions.</p>
                    <p><strong>8. Transparent Transactions:</strong> Printing Machine Deal has been acknowledged for its commitment to transparency, fostering trust between buyers and sellers.</p>
                    <p><strong>9. Valuable Resources:</strong> Our platform's "Helpful Resources" section has been praised for providing users with valuable guides and information to navigate the industry successfully.</p>
                    <p><strong>10. Positive Impact:</strong> We are proud of the positive impact we've had on businesses, both large and small, by helping them find the right machinery solutions for their needs.</p>

                </div>
            </section>

            <Footer />
        </>
    );
}
export default Accolades;
