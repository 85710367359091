import { Link , useHistory} from "react-router-dom";
import React, { useState } from 'react';
import axios from "axios";
import hostname from "../config";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Header from './include/Header';
import Footer from './include/Footer';

function ForgotPassword() {
   
    const history = useHistory();
   

    const [fogotpassword, setFogotpassword] = useState(true);
    const [changepassword, setChangepassword] = useState(false);

    const [showDiv, setShowDiv] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        otp: '',
      });

    const [changePasswordformDataObject, setChangePasswordformDataObject] = useState({
        email: '',
        new_password: '',
        confirm_password: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };
 
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formDataObject = new FormData();
        formDataObject.append('email', formData.email);
        formDataObject.append('otp', formData.otp);
        console.log(Object.fromEntries(formDataObject.entries()))
        try {
            var apiURl;
            if(formData.email){
                apiURl = `${hostname}/api/login/sendOtp`;
            }
            if(formData.email && formData.otp) {
                apiURl = `${hostname}/api/login/verifyOtp`;
            }
            const response = await axios.post(`${apiURl}`,formDataObject).then((response) => {
                console.log("response:::", response.data.message)
                if(response.data.status == 201) {
                    toast.error(response.data.message);
                }
                if(response.data.status == 200){
                    setTimeout(()=>{
                        toast.success(response.data.message);  
                        setShowDiv(true);
                        setIsReadOnly(true);   
                    },1000)
               }
               // after otp verify show reset password 
               if(response.data.status == 205){
                setTimeout(()=>{
                    toast.success(response.data.message);  
                    setFogotpassword(false);  
                    setChangepassword(true); 
                },1000)
           } 
            })
            .catch((error) => {
                console.error('Error inserting data:', error);
            });
        } catch(error) { }
    };

    const changePasswordSubmit = async (event) => {
        event.preventDefault();
        const changePasswordformDataObject = new FormData();
        changePasswordformDataObject.append('email', formData.email);
        changePasswordformDataObject.append('new_password', formData.new_password);
        changePasswordformDataObject.append('confirm_password', formData.confirm_password);
        console.log(Object.fromEntries(changePasswordformDataObject.entries()))
        try {
            const response = await axios.post(`${hostname}/api/login/changepassword`,changePasswordformDataObject).then((response) => {
                console.log("response:::", response.data.message)
                if(response.data.status == 201) {
                    toast.error(response.data.message);
                }
                if(response.data.status == 200){
                    toast.success(response.data.message);  
                    setTimeout(()=>{
                        history.push("/login")      
                    },1500)
               } 
            })
            .catch((error) => {
                console.error('Error inserting data:', error);
            });
        } catch(error) { }
    };

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">Forgot Password</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" >Forgot Password</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="login-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 mx-auto">
                            
                        {fogotpassword && 
                            <div className="login-wrap password-form">
                                <div className="login-header">
                                    <h3>Forgot Password</h3>
                                    <p>Enter your email and we will send you a link to reset your password.</p>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="form-group group-img">
                                        <div className="group-img">
                                            <i className="feather-mail"></i>
                                            <input type="text" name="email" className="form-control" placeholder="Email Address" onChange={handleInputChange} readOnly={isReadOnly}/>
                                        </div>
                                        {showDiv && 
                                           <><br></br>
                                             <div className="group-img">
                                                <i className="fas fa-lock"></i>
                                                <input type="text" name="otp" className="form-control" placeholder="Enter Email OTP" onChange={handleInputChange}/>
                                            </div>
                                            </>
                                        }
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Submit</button>
                                </form>
                                <Link className="back-home" to="/"><i className="fas fa-regular fa-arrow-left me-1"></i> Back to Home</Link>
                            </div>
                         }
                          
                        {changepassword && 
                            <div className="login-wrap password-form">
                                <div className="login-header">
                                    <h3>Reset Password</h3>
                                </div>
                                <form onSubmit={changePasswordSubmit}>
                                    <div className="form-group group-img">
                                        <div className="group-img">
                                            <i className="fas fa-lock"></i>
                                            <input type="password" name="new_password" className="form-control" placeholder="New Password" onChange={handleInputChange}/>
                                        </div>
                                        {showDiv && 
                                           <><br></br>
                                             <div className="group-img">
                                                <i className="fas fa-lock"></i>
                                                <input type="password" name="confirm_password" className="form-control" placeholder="Confirm Password" onChange={handleInputChange}/>
                                            </div>
                                            </>
                                        }
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Submit</button>
                                </form>
                                <Link className="back-home" to="/"><i className="fas fa-regular fa-arrow-left me-1"></i> Back to Home</Link>
                            </div>
                          }
                            
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark"/>
            </div>
            <Footer />
        </>
    );
}
export default ForgotPassword;
