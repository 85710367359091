import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function AboutUs() {
    return (
        <>
        <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">About Us</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="about-content">
                <div className="container">
                    <div className="about-listee">
                        <div className="about-img">
                            <img src="img/about.jpg" className="img-fluid" alt />
                        </div>
                        <div className="about-info">
                            <h4> <span>About</span> Printing Machine Deal</h4>
                            <p>Printing Machine Deal sounds like a specialized marketplace catering to the printing and packaging industry. Such platforms can be valuable for both sellers and buyers in this industry.</p>
                            <p>Printing Machine Deal seems to fill a niche in the market by connecting sellers and buyers of printing and packaging machines, streamlining the process, and providing a platform for efficient transactions within this specialized industry.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="howitworks">
                <div className="container">
                    <h3>How It Work</h3>
                    <p>Our mission is simple yet powerful: to create a trusted and user-friendly platform where businesses and individuals can buy and sell new and used printing and packaging machines with confidence and ease. We are committed to serving the unique needs of this industry by providing a dedicated space that brings together industry experts, entrepreneurs, and professionals.</p>

                </div>
            </section>
        <Footer />
        </>
    );
}
export default AboutUs;
