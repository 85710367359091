import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link} from 'react-router-dom';
import axios from "axios";
import hostname from "../config";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Header from './include/Header';
import Footer from './include/Footer';

function OldMachineDetails() {
     const history = useHistory();
      // States
      const [dataItems, setData] = useState([]);
      // get url id
       const location = useLocation();
       const queryParams = new URLSearchParams(location.search);
       const requestId = queryParams.get('id');
       let formData = new FormData();
       formData.append("productid", requestId);
       // Fetch Data
       const fetchInfo = () => {
           axios.post(`${hostname}/api/product/getOldMachineDetails`, formData)
               .then((response) => {
                   if(response.status == 200) {
                       console.log(response.data.results[0]);
                       setData(response.data.results[0])
                   }
               })
               .catch((error) => {
                   console.error('Error inserting data:', error);
               });
       };
       useEffect(() => {
           fetchInfo();
       }, []);

       const contactSeller = () => {
        if(!localStorage.getItem('userId')) {
           history.push("/login")
        } else {
          let contactSellerformData = new FormData();
          contactSellerformData.append("machinetype", "old");
          contactSellerformData.append("productid", requestId);
          contactSellerformData.append("buyerid", localStorage.getItem('userId'));
          //console.log(Object.fromEntries(contactSellerformData.entries()))
          axios.post(`${hostname}/api/product/contactSeller`, contactSellerformData)
          .then((response) => {
             if(response.data.status == 200) {
                toast.success(response.data.message);
             } else {
                toast.error(response.data.message);
             }
          })
          .catch((error) => {
              console.error('Error inserting data:', error);
          });
          
        }
   }

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">{dataItems.machine_name}</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                               <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" >{dataItems.machine_name}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-main-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="card gallery-section ">
                                <div className="card-header ">
                                    <img src="assets/img/galleryicon.svg" alt="gallery"/>
                                        <h4>Product Images</h4>
                                </div>
                                <div className="card-body">
                                    <div className="gallery-content">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-3">
                                                <div className="gallery-widget">
                                                    <a href={dataItems.image1} data-fancybox="gallery1">
                                                        <img className="img-fluid" alt="Image"
                                                            src={dataItems.image1}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3">
                                                <div className="gallery-widget">
                                                    <a href={dataItems.image2} data-fancybox="gallery1">
                                                        <img className="img-fluid" alt="Image"
                                                            src={dataItems.image2}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3">
                                                <div className="gallery-widget">
                                                    <a href={dataItems.image3} data-fancybox="gallery1">
                                                        <img className="img-fluid" alt="Image"
                                                            src={dataItems.image3}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3">
                                                <div className="gallery-widget me-0">
                                                    <a href={dataItems.image4} data-fancybox="gallery1">
                                                        <img className="img-fluid" alt="Image"
                                                            src={dataItems.image4}/>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card ">
                                <div className="card-header">
                                    <span className="bar-icon">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <h4>Description</h4>
                                </div>
                                <div className="card-body">
                                    {/* <p>{dataItems.description}</p> */}
                                    <span dangerouslySetInnerHTML={{ __html: dataItems.description }}></span>
                                    
                                    <div className="special-feature">
                                        <b>Special Feature: </b>
                                        {/* <span>{dataItems.speacial_feature}</span> */}
                                        <span dangerouslySetInnerHTML={{ __html: dataItems.speacial_feature }}></span>
                                    </div>
                                    <div className="technical-feature">
                                        <b>Technical Details: </b>
                                        {/* <span>{dataItems.technical_details}</span> */}
                                        <span dangerouslySetInnerHTML={{ __html: dataItems.technical_details }}></span>
                                    </div>
                                    <br></br>

                                    <button className="btn btn-primary w-100 login-btn" onClick={contactSeller} type="button">Contact Seller</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 theiaStickySidebar">
                            <div className="rightsidebar">
                                <div className="card">
                                    <h4><img src="assets/img/details-icon.svg" alt="details-icon"/> Details</h4>
                                    <ul>
                                        <li>Category <span>{dataItems.cat_id}</span></li>
                                        <li>Model No. <span>{dataItems.model_no}</span></li>
                                        <li>Quantity <span>{dataItems.quantity}</span></li>
                                        {/* <li>Price <span>{dataItems.price}</span></li> */}
                                        {dataItems.location > 0 ? (
                                            <li>Location<span>{dataItems.location}</span></li>
                                         ) : (
                                            <span></span>
                                         )}
                                        <li>Manufacuring Year <span>{dataItems.manufucturing_year}</span></li>
                                        <li>Country of Make <span>{dataItems.country_of_make}</span></li>
                                        <li>Hours Worked<span>{dataItems.hour_worked}</span></li>
                                    </ul>
                                </div>

                                <button className="btn btn-primary w-100 login-btn" onClick={contactSeller} type="button">Contact Seller</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark"/>
            </div>
            <Footer />
        </>
    );
}
export default OldMachineDetails;