import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import axios from "axios";
import hostname from "../config";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Header from './include/Header';
import Footer from './include/Footer';

function Register() {
    const history = useHistory();
    console.log(history);
    const [formData, setFormData] = useState({
        name: '',
        company_name: '',
        email: '',
        password: '',
        mobile_no: '',
        address: '',
        country: '',
      });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formDataObject = new FormData();
        formDataObject.append('name', formData.name);
        formDataObject.append('company_name', formData.company_name);
        formDataObject.append('email', formData.email);
        formDataObject.append('password', formData.password);
        formDataObject.append('mobile_no', formData.mobile_no);
        formDataObject.append('address', formData.address);
        formDataObject.append('country', formData.country);
        //console.log(Object.fromEntries(formDataObject.entries()))
        try {
            const response = await axios.post(`${hostname}/api/login/register`,formDataObject).then((response) => {
                console.log("response", response);
                if(response.data.status == 201) {
                    toast.error('Email Id is already exists, please try another');
                }
               if(response.data.status == 200){
                    toast.success('Registration Successfully...');
                    setTimeout(()=>{
                        history.push("/login")           
                    },1000)
               } 
            })
            .catch((error) => {
                console.error('Error inserting data:', error);
            });
        } catch(error) { }
    };

    return (
        <>
            <Header />
            <div class="breadcrumb-bar">
                <div class="container">
                    <div class="row align-items-center text-center">
                        <div class="col-md-12 col-12">
                            <h2 class="breadcrumb-title">Create an Account</h2>
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                               <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" >Create an Account</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <div class="login-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-5 mx-auto">
                            <div class="login-wrap register-form">
                                <div class="login-header">
                                    <h3>Create an Account</h3>
                                    {/* <p>Lets start with <span>Listee</span></p> */}
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-user"></i>
                                            <input type="text" required class="form-control" name="name" placeholder="Name" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-user"></i>
                                            <input type="text" required class="form-control" name="company_name" placeholder="Company Name" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-mail"></i>
                                            <input type="text" required class="form-control" name="email" placeholder="Email Address" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="pass-group group-img">
                                            <i class="feather-lock"></i>
                                            <input type="password" required name="password" class="form-control pass-input" placeholder="Password" onChange={handleInputChange}/>
                                            <span class="toggle-password feather-eye"></span>
                                        </div>
                                    </div>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-mail"></i>
                                            <input type="text" required class="form-control" name="mobile_no" placeholder="Mobile No." onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-mail"></i>
                                            <input type="text" required class="form-control" name="address" placeholder="Address" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div class="form-group group-img">
                                        <div class="group-img">
                                            <i class="feather-mail"></i>
                                            <input type="text" required class="form-control" name="contary" placeholder="Country" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary w-100 login-btn" type="submit">Create Account</button>
                                    <div class="register-link text-center">
                                        <p>Already have an account? <Link class="forgot-link" to="/login">Sign In</Link></p>
                                    </div>
                                    {/* <div class="login-or">
                                        <span class="or-line"></span>
                                        <span class="span-or">Sign in with Social Media Accounts</span>
                                    </div>
                                    <div class="social-login">
                                        <a href="#" class="btn btn-apple w-100"><img src="assets/img/apple.svg" class="me-1" alt="img" />Sign in with Apple</a>
                                    </div>
                                    <div class="social-login">
                                        <a href="#" class="btn btn-google w-100"><img src="assets/img/google.svg" class="me-1" alt="img" />Sign in with Google</a>
                                    </div>
                                    <div class="social-login">
                                        <a href="#" class="btn btn-facebook w-100 mb-0"><img src="assets/img/facebook.svg" class="me-2" alt="img" />Continue with Facebook</a>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark"/>
            </div>
            <Footer />
        </>
    );
}
export default Register;