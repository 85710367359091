import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Resources() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Resources</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Resources</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="howitworks">
                <div class="container">
                    <br></br>
                    <br></br>
                    <p><strong>Guides, FAQs, and Support</strong></p>
                    <p>
                        In our Helpful Resources section, you'll find valuable guides, FAQs, and customer support information to assist you in navigating our platform. We are committed to providing the information and assistance you need for a successful experience on Printing Machine Deal.
                    </p>

                </div>
            </section>

            <Footer />
        </>
    );
}
export default Resources;
