import { Link, useHistory } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer footer-seven">
            <div className="footer-top aos" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">About</h2>
                                <ul>
                                    <li>
                                       <Link to="/about-us">About us</Link>
                                    </li>
                                    <li>
                                    <Link to="/management">Management</Link>
                                    </li>
                                    <li>
                                    <Link to="/careers">Career</Link>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">Sitemap</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Featured</h2>
                                <ul>
                                    <li>
                                      <Link to="/buy-new-machine">Buy New Machine</Link>
                                    </li>
                                    <li>
                                       <Link to="/buy-use-machine">Buy Used Machine</Link>
                                    </li>
                                    <li>
                                       <Link to="/buy-accessories">Buy Accessories</Link>
                                    </li>
                                    <li>
                                        <a href="https://printingmachinedeal.com/admin">Sell Machine</a>
                                    </li>
                                    <li>
                                    <Link to="/login">Buyer Login</Link>
                                    </li>
                                    <li>
                                        <a href="https://printingmachinedeal.com/admin">Seller Login</a>
                                    </li>

                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Quick Links</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/disclaimer">Disclaimer</Link></li>
                                    <li><Link to="/accolades">Accolades</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Imp Links</h2>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                    
                                    <li><Link to="/refund-policy">Refund Policy</Link></li>
                                    <li><Link to="/resources">Helpful Resources</Link></li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-6">

                            <div className="footer-widget">
                                <h2 className="footer-title">Contact Us </h2>
                                <div className="footer-contact-info">
                                    <div className="footer-address">
                                        <p className="footer-icon"><i className="fa-solid fa-location-dot"></i></p>
                                        <p><b>Inpack Solutions India</b> B-06, Logix Technova, Sector -132, Noida, India - 201304</p>
                                    </div>
                                    <div className="footer-address">
                                        <p className="footer-icon"><i className="fa-solid fa-phone"></i></p>
                                        {/* <p> +91 - 0120 4354214 </p> */}
                                        <p> +91 - 9811226914 </p>
                                    </div>
                                    <div className="footer-address">
                                        <p className="footer-icon"><i className="fa-solid fa-envelope"></i></p>
                                        {/* <p> support@printingmachinedeal.com</p> */}
                                        <p> bhupendra@inpack.in </p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="footercount">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="vistors-details">

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 ">
                                <div className="vistors-details text-lg-center">
                                    <div className="social-icon">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0)"><i className="fab fa-facebook-square"></i> </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i className="fab fa-youtube"></i> </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i className="fab fa-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/printing-machine-deal/about/" target='_blank'><i className="fab fa-linkedin"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://wa.me/919811226914" target='_blank'><i className="fab fa-whatsapp"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 ">
                                <div className="vistors-details text-lg-end">
                                    <p>© 2023 Printing Machine Deal. All Rights Reserved </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </footer>
    );
}

export default Footer;
