import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Privacy Policy</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container">
                    <br></br>
                    <br></br>
                    {/* <p><strong>Our Commitment to Your Privacy</strong></p>
                    <p>At Printing Machine Deal, we are dedicated to safeguarding your privacy. This Privacy Policy outlines our practices regarding the collection, use, and protection of your personal information. By using our platform, you consent to the practices described herein.</p>
                    <p><strong>Information We Collect</strong></p>
                    <p>We may collect personal information, such as your name, contact details, and payment information, to facilitate your transactions and provide you with a seamless experience on our platform. We also collect non-personal information, such as browsing history and user preferences, to enhance our services.</p>
                    <p><strong>How We Use Your Information</strong></p>
                    <p>We use your information to process transactions, improve our platform, and provide personalized recommendations. We do not sell or share your personal information with third parties for their marketing purposes without your consent.</p>
                    <p><strong>Security Measures</strong></p>
                    <p>We employ industry-standard security measures to protect your data. However, please be aware that no data transmission over the internet can be guaranteed as 100% secure.</p> */}

                    <div class="m-b30">

                        <h6><strong>1. Introduction</strong></h6>
                        <p>1.1 We are committed to safeguarding the privacy of our visitors; in this policy we explain how we will treat your personal information.</p>
                        <p>1.2 By using our platform and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
                        <h6><strong>2. Collecting information and use</strong></h6>
                        <p>2.1 Personal Information: We may collect, store and use the following kinds of personal and professional information:</p>
                        <p>(a) [information about your computer and about your visits to and use of this platform (including [your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and platform navigation paths])];</p>
                        <p>(b) [information that you provide to us when registering with our platform (including [your email address])];</p>
                        <p>(c) [information that you provide when completing your profile on our platform (including [your name, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details and employment details])];</p>
                        <p>(d) [information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (including [your name and email address])];</p>
                        <p>(e) [information that you provide to us when using the services on our platform, or that is generated in the course of the use of those services (including [the timing, frequency and pattern of service use])];</p>
                        <p>(f) [information that you post to our platform for publication on the internet (including [your user name, your profile pictures and the content of your posts])];</p>
                        <p>(g) [information contained in or relating to any communication that you send to us or send through our platform (including [the communication content and metadata associated with the communication])]; and</p>
                        <p>3.4 Before you disclose to us the personal information of another person, you must obtain that person’s consent to both the disclosure and the processing of that personal information in accordance with this policy.</p>
                        <h6><strong>4. Using personal information</strong></h6>
                        <p>4.1 Personal information submitted to us through our platform will be used for the purposes specified in the terms and conditions or on the relevant pages of the platform.</p>
                        <p>4.2 We may use your personal information for:</p>
                        <p>(a) [administer our platform and business];</p>
                        <p>(b) [personalise our platform for you];</p>
                        <p>(c) [enable your use of the services available on our platform];</p>
                        <p>(d) [send you email notifications that you have specifically requested];</p>
                        <p>(e) [send you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter)]; to club with above</p>
                        <p>(f) [provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information)];</p>
                        <p>(g) [deal with enquiries and complaints made by or about you relating to our platform];</p>
                        <p>(h) [keep our platform secure and prevent fraud];</p>
                        <p>(i) In the event of restructuring or acquisitions or liquidation, business transfers</p>
                        <h6><strong>5. Disclosing personal information</strong></h6>
                        <p>5.1 We may disclose your personal information to [any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors] insofar as reasonably necessary for the purposes set out in the terms and conditions of the platform</p>
                        <p>5.2 We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</p>
                        <p>5.3 We may disclose your personal information:</p>
                        <p>(a) to the extent that we are required to do so by law;</p>
                        <p>(b) in connection with any ongoing or prospective legal proceedings;</p>
                        <p>(c) in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention);</p>
                        <p>(d) [to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information].</p>
                        <p>5.4 Parties with whom we may share your information:</p>
                        <ul>
                            <li>We may share User Content and your information (including but not limited to, information from cookies, log files, device identifiers, location data, and usage data) with businesses that are legally part of the same group of companies that Printing Machine Deal is part of, or that become part of that group (“Affiliates”). Affiliates may use this information to help provide, understand, and improve the Service (including by providing analytics) and Affiliates’ own services (including by providing you with better and more relevant experiences). But these Affiliates will honor the choices you make about who can see your photos.</li>
                        </ul>
                        <ul>
                            <li>We also may share your information as well as information from tools like cookies, log files, and device identifiers and location data, with third-party organizations that help us provide the Service to you (“Service Providers”). Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.</li>
                            <li>We may also share certain information such as cookie data with third-party advertising partners. This information would allow third-party ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you.</li>
                            <li>We may remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.</li>
                        </ul>
                        <h6><strong>5.5 Except as provided in this policy, we will not provide your personal information to third parties.</strong></h6>
                        <h6><strong>6. International data transfers</strong></h6>
                        <p>6.1 Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.</p>
                        <p>6.2 Information that we collect may be transferred to the any country, irrespective of the fact that such countries where user may reside, does not have data protection laws equivalent to those in force in the European Economic Areas.</p>
                        <p>6.3 Personal information that you publish on our platform or submit for publication on our platform may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others.</p>
                        <p>6.4 You expressly agree to the transfers of personal information described in this Section 6.</p>
                        <h6><strong>7. Retaining personal information</strong></h6>
                        <p>7.1 This Section 7 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information.</p>
                        <p>7.2 Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
                        <p>7.3 Notwithstanding the other provisions of this Section 7, we will retain documents (including electronic documents) containing personal data:</p>
                        <p>7.4 Notwithstanding the other provisions of this Section 7, we will retain documents (including electronic documents) containing personal data:</p>
                        <p>(a) to the extent that we are required to do so by law;</p>
                        <p>(b) if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</p>
                        <p>(c) in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</p>
                        <h6><strong>8. Security of personal information</strong></h6>
                        <p>8.1 We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
                        <p>8.2 We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</p>
                        <p>8.3 You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
                        <p>8.4 You are responsible for keeping the password you use for accessing our platform confidential; we will not ask you for your password (except when you log in to our platform).</p>
                        <h6><strong>9. Amendments</strong></h6>
                        <p>9.1 We may update this policy from time to time by publishing a new version on our platform.</p>
                        <h6><strong>10. Your rights</strong></h6>
                        <p>10.1&nbsp;We may withhold personal information that you request to the extent permitted by law.</p>
                        <p>10.2 Objection Right – You may at anytime object the usage of your data in the event you have reasons to believe that the data is being used by our Company, without your consent for any purpose other than as authorised under the user terms and conditions agreement.</p>
                        <p>10.3&nbsp;Withdrawal of Consent – You may at any time withdraw your consent given earlier regarding your data usage.</p>
                        <p>10.4&nbsp;Destruction/Erasure of Data – You may at any time request for the deletion, erasure or destruction of your data from the systems or other storage sources used for the collection and storage of your data. This right is specifically granted in the event when a) data is no longer required and the purpose if accomplished, b) unlawful processing or use of data c) withdrawal of consent, d) objection of use for business development and marketing.</p>
                        <p>10.5&nbsp;Data Transfer – You can request in writing, portability of your data in a structured and commercially acceptable format.</p>
                        <h6><strong>11. Third party platforms</strong></h6>
                        <p>11.1 Our platform includes hyperlinks to, and details of, third party platforms.</p>
                        <p>11.2 We have no control over, and are not responsible for, the privacy policies and practices of third parties.</p>
                        <h6><strong>12. Updating information</strong></h6>
                        <p>12.1 If you are a registered user of our Services, we provide you with tools and account settings to access or modify the profile information you provided to us and associated with your account.</p>
                        <p>You can also permanently delete your Printing Machine Deal account by&nbsp;contacting us.</p>
                        <h6><strong>13. Cookies</strong></h6>
                        <p>13.1 Our platform uses cookies.</p>
                        <p>13.2 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
                        <p>13.3 Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
                        <p>13.4 Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
                        <p>13.5 We use [only session cookies] OR [only persistent cookies] OR [both session and persistent cookies] on our platform.</p>
                        <p>13.6 The names of the cookies that we use on our platform, and the purposes for which they are used, are set out below:</p>
                        <p>(a) we use&nbsp;<em>[cookie name]</em>&nbsp;to [recognise a computer when a user visits our platform] OR [track users as they navigate the platform] OR [enable the use of a shopping cart on the platform] OR [improve the platform’s usability] OR [administer the platform] OR [prevent fraud and improve the security of the platform] OR [personalise the platform for each user] OR [target advertisements which may be of particular interest to specific users] OR [validate authenticated users sessions] OR [facilitate the use of our platform search engine] OR&nbsp;<em>[specify purpose]</em>.</p>
                        <p>13.7 Most browsers allow you to refuse to accept cookies; for example:</p>
                        <p>(a) in Internet Explorer (version 11) you can block cookies using the cookie handling override settings available by clicking “Tools”, “Internet Options”, “Privacy” and then “Advanced”;</p>
                        <p>(b) in Firefox (version 47) you can block all cookies by clicking “Tools”, “Options”, “Privacy”, selecting “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and</p>
                        <p>(c) in Chrome (version 52), you can block all cookies by accessing the “Customise and control” menu, and clicking “Settings”, “Show advanced settings” and “Content settings”, and then selecting “Block sites from setting any data” under the “Cookies” heading.</p>
                        <p>13.8 Blocking all cookies will have a negative impact upon the usability of many platforms.</p>
                        <p>13.9 If you block cookies, you will not be able to use all the features on our platform.</p>
                        <p>13.10 You can delete cookies already stored on your computer; for example:</p>
                        <p>(a) in Internet Explorer (version 11), you must manually delete cookie files (you can find instructions for doing so at http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);</p>
                        <p>(b) in Firefox (version 47), you can delete cookies by clicking “Tools”, “Options” and “Privacy”, then selecting “Use custom settings for history” from the drop-down menu, clicking “Show Cookies”, and then clicking “Remove All Cookies”; and</p>
                        <p>(c) in Chrome (version 52), you can delete all cookies by accessing the “Customise and control” menu, and clicking “Settings”, “Show advanced settings” and “Clear browsing data”, and then selecting “Cookies and other site and plug-in data” before clicking “Clear browsing data”.</p>
                        <p>13.11 Deleting cookies will have a negative impact on the usability of many platforms.</p>
                        <h6><strong>14. Our details</strong></h6>
                        <p>14.1 This platform is owned and operated by Inpack Solutions India.</p>
                    </div>


                </div>
            </section>

            <Footer />
        </>
    );
}
export default PrivacyPolicy;
