import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Disclaimer() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Disclaimer</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Disclaimer</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container">
                    <br></br>
                    <br></br>
                    <p><strong>Not Legal or Professional Advice</strong></p>
                    <p>
                        The information provided on Printing Machine Deal is for general informational purposes only. It should not be construed as legal, financial, or professional advice. We do not assume any responsibility or liability for the accuracy or completeness of the information provided on our platform.
                    </p>

                </div>
            </section>

            <Footer />
        </>
    );
}
export default Disclaimer;
