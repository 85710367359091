import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function RefundPolicy() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Refund Policy</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Refund Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container">
                    <br></br>
                    <br></br>
                    {/* <p><strong>Your Satisfaction Matters</strong></p>
                    <p>At Printing Machine Deal, we want you to be satisfied with your purchases. Our Refund Policy outlines the conditions and process for requesting refunds. If you are not completely happy with your purchase, please review this policy to understand your options.</p> */}

                    <div class="m-b30">
                        <h4>PAYMENT TERMS &amp; REFUND POLICY:</h4>
                        <p>YOU ARE REQUESTED TO HONOUR YOUR PAYMENT OBLIGATIONS TO THE COMPANY.</p>
                        <p>Payments for the services offered by the Printing Machine Deal Platform shall be on a 100% advance basis.&nbsp;<strong>The payment for service once done by you is not refundable and any amount paid shall stand appropriated</strong>. Refund, if any, will be at the sole discretion of the Company.</p>
                        <p>User hereby irrevocably accepts to receive the tax invoice as soft copy through emails.</p>
                        <p>Notwithstanding anything contained in any other agreement or arrangement, by whatever name called, the performance obligation of the Company (service provider) is to provide access of its on-line portal to the customer for the duration of the subscription period &amp; reference to any usage, by whatever name called or any other performance obligation, if any, is to provide the upper limit for consumption, which by itself, does not create any additional performance obligation upon the Company<br></br>The Company offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Customers card/bank accounts.</p>
                        <p>In the event of any suspension or termination of services on account of non-compliance of these Terms of Use, any payment made to the Company by you shall stand forfeited with immediate effect.</p>
                        <p>The User acknowledges and agrees that Company/Platform, at its sole discretion and without prejudice to other rights and remedies that it may have under the applicable laws, shall be entitled to set off the amount excess paid by a subscriber/user against any amount(s) payable by User to Company under any other agreement or commercial relationship towards other products/services. The Company gives no guarantees of server uptime or applications working properly. All is on a best effort basis and liability is limited to refund of amount only. We undertake no liability for free services.</p>
                        <p>The User or customer shall be required to promptly provide copy of TDS certificate to the Company as mandated under law for tax deducted at source from the payments made to the Company. Any loss on account of non availment or disallowance of such TDS credit in view of any lapse as per this clause or as required under applicable law on the part of User or customer shall entitle the company to seek reimbursement or indemnification</p>
                        <p>Users, buying the products online are redirected to third party gateways for completing payment transactions. These transactions happen on third party network and hence not controlled by Company.<br></br>A User through his user identification and shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions and Company assumes no
                            responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line</p>
                        <p>The Inpack Solutions India shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the user's account and / or information relating to or regarding online transactions using credit cards / debit cards and / or their verification process and particulars nor for any error, omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a legal process or otherwise.</p>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
export default RefundPolicy;
