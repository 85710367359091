import Header from './include/Header';
import Footer from './include/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Careers() {
    return (
        <>
            <Header />
            <div className="aboutbanner innerbanner">
                <div className="inner-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12 col-12 ">
                                <h2 className="breadcrumb-title">Careers</h2>
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" >Careers</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="howitworks">
                <div className="container">
                    <br></br>
                    <br></br>
                    <p><strong>Join Our Team at Printing Machine Deal</strong></p>
                    <p>Are you looking for an exciting and dynamic career opportunity in the world of e-commerce and machinery sales? At Printing Machine Deal, we're on a mission to revolutionize the printing and packaging machinery industry, and we're looking for talented individuals like you to help us achieve our goals.</p>
                    <p><strong>Why Choose a Career at Printing Machine Deal?</strong></p>
                    <ol>
                        <li><strong>Innovative Environment</strong>: Join a team of forward-thinkers and innovators who are passionate about creating a cutting-edge platform that connects buyers and sellers in the printing and packaging machinery sector.</li>
                        <li><strong>Impactful Work</strong>: Be part of a company that plays a crucial role in streamlining and simplifying the machinery buying and selling process. Your contributions will directly impact the success of businesses in this industry.</li>
                        <li><strong>Collaborative Culture</strong>: We believe in the power of collaboration. You'll work alongside motivated and talented colleagues who value teamwork, open communication, and a shared commitment to excellence.</li>
                        <li><strong>Professional Growth</strong>: We invest in our employees' professional growth and development. Whether you're just starting your career or looking to advance, Printing Machine Deal provides opportunities for skill enhancement and career progression.</li>
                        <li><strong>Customer-Centric Focus</strong>: Our customers are at the heart of everything we do. Join a team that is dedicated to providing outstanding customer support and delivering a positive user experience.</li>
                    </ol>
                    <p><strong>Contact Us</strong></p>
                    <p>If you have any questions about our career opportunities or the application process, please feel free to contact our Human Resources team at info@printingmachinedeal.com</p>

                </div>
            </section>


            <Footer />
        </>
    );
}
export default Careers;
