import React, { useState, useEffect } from "react";
import Header from './include/Header';
import Footer from './include/Footer';
import axios from "axios";
import { Link, useHistory } from 'react-router-dom';
import hostname from "../config";


function Profile() {
     const history = useHistory();
     const [buyerInfo, setBuyerInfo] = useState([]);

     // Buyer Request Information:
     const [dataItems, setData] = useState([]); 
     let formData = new FormData();
     formData.append("userid", localStorage.getItem('userId'));
     // Fetch Data
     const fetchInfo = () => {
         axios.post(`${hostname}/api/profile`, formData)
             .then((response) => {
                 if(response.status == 200) {
                     setData(response.data.buyerRequestInfo);
                     setBuyerInfo(response.data.buyerInfo);
                 }
             })
             .catch((error) => {
                 console.error('Error inserting data:', error);
             });
     };

     useEffect(() => {
         fetchInfo();
     }, []);

    const logout = async (event) => {
        localStorage.removeItem('name');
        localStorage.removeItem('userId');  
        history.push("/login")
    };

    return (
        <>
        <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">Profile</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dashboard-content">
                <div className="container">
                    <div className>
                        <ul className="dashborad-menus">
                            <li className="active">
                                <a href="javascript:void(0)">
                                    <i className="fa-solid fa-user"></i> <span>Profile</span>
                                </a>
                            </li>

                            <li>
                                <a href="javascritp:void(0)" onClick={logout}>
                                    <i className="fas fa-light fa-circle-arrow-left"></i> <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="profile-content">
                        <div className="row dashboard-info">
                            <div className="col-lg-12">
                                <div className="card dash-cards">
                                    <div className="card-header">
                                        <h4>Profile Details</h4>
                                    </div>
                                    <div className="card-body">

                                        <div className="profile-form">

                                            <form>
                                            { buyerInfo.map((item, key) => {
                                                return (<div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Name</label>
                                                            <div className="pass-group group-img">
                                                                <span className="lock-icon"><i className="feather-phone-call"></i></span>
                                                                <input type="text" className="form-control" name="name" value={item.name}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Company Name</label>
                                                            <div className="pass-group group-img">
                                                                <span className="lock-icon"><i className="feather-phone-call"></i></span>
                                                                <input type="text" className="form-control" value={item.company_name} name="company_name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Mobile Number</label>
                                                            <div className="pass-group group-img">
                                                                <span className="lock-icon"><i className="feather-phone-call"></i></span>
                                                                <input type="tel" className="form-control" value={item.mobile_no} name="mobile_no"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Email Address</label>
                                                            <div className="group-img">
                                                                <i className="feather-mail"></i>
                                                                <input type="text" className="form-control" value={item.email} name="email"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Address</label>
                                                            <div className="group-img">

                                                                <input type="text" name="address" value={item.address} className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Country</label>
                                                            <div className="group-img">

                                                                <input type="text" name="country" value={item.country} className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <button className="btn btn-primary" type="submit" disabled> Save</button>
                                                    </div>
                                                </div>)
                                                 })}
                                            </form>

                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="listing-table datatable" id="listdata-table">
                                                        <thead>
                                                            <tr>
                                                                <th className="no-sort">Sr No.</th>
                                                                <th className="no-sort">Machine Name</th>
                                                                <th className="no-sort">Category</th>
                                                                <th className="no-sort">Sub Category</th>
                                                                <th>Type</th>
                                                                <th className="no-sort">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { dataItems.map((item, index, key) => {
                                                                return (<tr>
                                                                        <td>{index+1}</td>
                                                                        <td><h6>{item.machine_name}</h6></td>
                                                                        <td>{item.category_name}</td>
                                                                        <td>{item.sub_category_name}</td>
                                                                        <td>                                                                          
                                                                            {item.machine_type === 'new' ? (
                                                                              <span className="status-text">New</span>
                                                                            ) : (
                                                                                <span className="status-text">Used</span>
                                                                            )}
                                                                        </td>
                                                                        <td><span className="views-count">Enquired</span></td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Profile;