import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

function Header() {
    const history = useHistory();
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    const [username, setusername] = useState(localStorage.getItem('name'));

    const logout = async (event) => {
        localStorage.removeItem('name');
        localStorage.removeItem('userId');  
        history.push("/login")
    };
    
    return (
        <header className="header header-four">
            <div className="container">
                <nav className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <a id="mobile_btn" href="javascript:void(0);">
                            <span className="bar-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </a>
                        <Link to="/">
                            <img src="img/logo.png" className="img-fluid" alt="Logo" />
                        </Link>
                    </div>
                    <div className="main-menu-wrapper">
                        <div className="menu-header">
                            <a href="index.html" className="menu-logo">
                                <img src="img/logo.png" className="img-fluid" alt="Logo" />
                            </a>
                            <a id="menu_close" className="menu-close" href="javascript:void(0);">
                                <i className="fas fa-times"></i>
                            </a>
                        </div>
                        <ul className="main-nav">
                            <li><Link to="/buy-use-machine">Buy / Sell Used Machine</Link></li>
                            <li><Link to="/buy-new-machine">Buy / Sell New Machine</Link></li>
                            <li><Link to="/buy-accessories">Buy / Sell Accessories</Link></li>
                            {/* <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact</Link></li> */}
                           
                            <li className="login-link">
                               {userId ? (
                                <div class="dropdown">
                                   <button class="btn btn-secondary dropdown-toggle nav-link header-login mobile-menu-dropdown " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                       My Account
                                   </button>
                                   <ul class="dropdown-menu">
                                     <li><a href="javascript:void(0);" className="dropdown-item profileItem">{username}</a></li>
                                     <li><Link to="/profile" className="dropdown-item profileItem">Profile</Link></li>
                                     <li><a class="dropdown-item profileItem" href="javascript:void(0)" onClick={logout}>Logout</a></li>
                                   </ul>
                                </div>    
                                ) : (
                                <Link to="/login" className="nav-link header-login"><i className="fa-solid fa-plus"></i>
                                    Buyer Login</Link>
                                )}
                            </li>
                            <li className="login-link">
                                <a className="nav-link header-login" href="https://printingmachinedeal.com/admin" target="_blank"> <i className="fa-solid fa-plus"></i> Seller Login
                                </a>
                            </li> 
                        </ul>
                        <ul className="nav header-navbar-rht nav">
                            <li className="nav-item">
                               {userId ? (
                                <div class="dropdown">
                                   <button class="btn btn-secondary dropdown-toggle nav-link header-login" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                       My Account
                                   </button>
                                   <ul class="dropdown-menu">
                                     <li><a  href="javascript:void(0);" className="dropdown-item profileItem">{username}</a></li>
                                     <li><Link to="/profile" className="dropdown-item profileItem">Profile</Link></li>
                                     <li><a class="dropdown-item profileItem" href="javascript:void(0)" onClick={logout}>Logout</a></li>
                                   </ul>
                                </div> 
                                    
                                ) : (
                                 <Link to="/login" className="nav-link header-login"><i className="fa-solid fa-plus"></i>
                                    Buyer Login</Link>
                                )}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link header-login" href="https://printingmachinedeal.com/admin" target="_blank"> <i className="fa-solid fa-plus"></i> Seller Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
