import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import hostname from "../../../src/config";
import Header from '../include/Header';
import Footer from '../include/Footer';

function Home() {
    const history = useHistory();
    const categoryUrl = `${hostname}/api/category/getCategoryList`;
    const subCategoryUrl = `${hostname}/api/category/getSubCategoryList`;
    const [dataItems, setData] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    //States for categorya and sub category:
    const [CategoryId, setCategoryId] = useState([]);
    const [SubCategoryId, setSubCategoryId] = useState([]);

    const getCategoryInfo = async () => {
        return await axios.get(categoryUrl).then((res) => setData(res.data.category));
    };

    const getSubCategory = async (event) => {
        const value = event.target.value;
        setCategoryId(value);
        return await axios.get(subCategoryUrl + '?cat_id=' + value).then((res) => setSubCategory(res.data.sub_category));
    }

    const search = async (event) => {
        event.preventDefault();
        history.push('/search?cat=' + CategoryId + '&sub_cat=' + SubCategoryId);
    }

    useEffect(() => {
        getCategoryInfo();
    }, []);

    return (
        <>
            <Header />
            <section className="banner-section banner-seven">
                <div className="container">
                    <div className="home-banner">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="section-search aos" data-aos="fade-up">
                                    <p>Discover the best machines.</p>
                                    <h1>Find / Sell a printing machine with us.</h1>
                                    <div className="search-box search-box-seven">
                                        <form onSubmit={search}
                                            className="form-block d-flex"
                                        >
                                            <div className="search-input line">
                                                <div className="form-group mb-0">
                                                    <select className="form-control select category-select" onChange={getSubCategory} required>
                                                        <option value="">Choose Category</option>
                                                        {dataItems.map((item, key) => {
                                                            return (<option value={item.id}>{item.category_name}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="search-input line">
                                                <div className="form-group select-location mb-0">
                                                    <select className="form-control select category-select" onChange={(event) => { setSubCategoryId(event.target.value) }} required>
                                                        <option value="">Choose Sub Category</option>
                                                        {subCategory.map((item, key) => {
                                                            return (<option value={item.id}>{item.category_name}</option>)
                                                        })}
                                                    </select>
                                                    <i className="feather-map-pin" />
                                                </div>
                                            </div>
                                            <div className="search-btn">
                                                <button className="btn btn-primary" type="submit">
                                                    Search Now
                                                    <i className="fa fa-search" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="banner-imgs aos" data-aos="fade-up">
                                    <div className="main-banner-1">
                                        <img
                                            src="img/printing.jpeg"
                                            className="img-fluid "
                                            alt="bannerimage"
                                        />
                                    </div>
                                    <div className="main-banner-2">
                                        <img
                                            src="img/blowline.jpeg"
                                            className="img-fluid "
                                            alt="bannerimage"
                                        />
                                    </div>
                                    <div className=" main-banner-3">
                                        <img
                                            src="img/dispatch.jpg"
                                            className="img-fluid"
                                            alt="bannerimage"
                                        />
                                    </div>
                                    <div className="main-banner-4">
                                        <img
                                            src="img/sliting.jpg"
                                            className="img-fluid "
                                            alt="bannerimage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-bg-img">
                            <div className="bg-one">
                                <img
                                    src="assets/img/bg/banner-left-bg.png"
                                    className="img-fluid"
                                    alt="image"
                                />
                            </div>
                            <div className="bg-two">
                                <img
                                    src="assets/img/bg/banner-right-bg.png"
                                    className="img-fluid "
                                    alt="image"
                                />
                            </div>
                            <div className="bg-three">
                                <img
                                    src="assets/img/bg/banner-bottom-bg.png"
                                    className="img-fluid "
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-section">
                <div className="container">
                    <div className="section-heading">
                        <div className="row align-items-center">
                            <div className="col-md-6 aos aos-init aos-animate" data-aos="fade-up">
                                <h2>Our <span className="title-left">Cat</span>egory</h2>
                                <p>Buy and Sell Everything from Used Our Top Category</p>
                            </div>
                            {/* <div className="col-md-6 text-md-end aos aos-init aos-animate" data-aos="fade-up">
                                <a href="categories.php" className="btn  btn-view">View All</a>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        {dataItems.map((item, key) => {
                            return (
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <Link to={`/search?cat=${item.id}`} className="category-links">
                                        <h5>{item.category_name}</h5>
                                        <img src={item.url} alt="icons" width={100} height={90} />
                                    </Link>
                                </div>)
                        })}
                    </div>
                </div>
            </section>

            <section className="car-testimonial common-padding ">
                <div className="container">
                    <div className="row aos aos-init aos-animate" data-aos="zoom-in">
                        <div className="col-lg-4 col-md-6">
                            <div className="car-testimonial-content">
                                <h3>.01</h3>
                                <h5>Create Account</h5>
                                <p>Are you buyer or seller, Just register and start exploring the machines or enquries !</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="car-testimonial-content">
                                <h3>.02</h3>
                                <h5>Add Or Find Machines</h5>
                                <p>Add your old or new machine or find a machine in once click</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="car-testimonial-content">
                                <h3>.03</h3>
                                <h5>Start Getting Leads</h5>
                                <p>Easy to generate enquires for your machines or start contacting the sellers .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="testimonial-section-seven">
                <div className="container">
                    <div className="section-heading-two section-heading-seven text-center">
                        <div className="row">
                            <div className="col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                                <img src="assets/img/heading-logo-small.png" className="img-fluid" alt="title-img"/>
                                    <h2>People Talking About Us</h2>
                                    <p>Our biggest clients</p>
                            </div>
                        </div>
                    </div>
                    <div className="testi-carousel ">
                        <div className="test-slider owl-carousel owl-theme">
                            <div className="testi-widget-seven aos" data-aos="fade-up">
                                <div className="test-details">
                                    <div className="icon-blk">
                                        <img src="assets/img/profiles/avatar-01.jpg" alt="Dreamstrip Testimonials"/>
                                    </div>
                                    <div className="people-name">
                                        <h5>Mohit</h5>
                                        <div className="review">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-content">
                                    <h5>Great Services!!!</h5>
                                    <p>I was amazed at how quickly I found a buyer for my printing press on Printing Machine Deal. The platform's user-friendly interface made listing my machine a breeze. Within a week, I had multiple inquiries, and I successfully sold my equipment. Highly recommended!"
                                    </p>
                                </div>
                                <div className="testi-location">
                                    <h6></h6>
                                    <p><i className="feather-map-pin"></i> Chennai</p>
                                </div>
                            </div>
                            <div className="testi-widget-seven aos" data-aos="fade-up">
                                <div className="test-details">
                                    <div className="icon-blk">
                                        <img src="assets/img/profiles/avatar-02.jpg" alt="Dreamstrip Testimonials"/>
                                    </div>
                                    <div className="people-name">
                                        <h5>Neerav</h5>
                                        <div className="review">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-content">
                                    <h5>Good !!!</h5>
                                    <p>As a startup in the packaging industry, finding affordable machinery was crucial. Printing Machine Deal made it easy for me to search for and connect with sellers. The range of options and the responsiveness of sellers exceeded my expectations.
                                    </p>
                                </div>
                                <div className="testi-location">
                                    <h6></h6>
                                    <p><i className="feather-map-pin"></i> Delhi</p>
                                </div>
                            </div>
                            <div className="testi-widget-seven aos" data-aos="fade-up">
                                <div className="test-details">
                                    <div className="icon-blk">
                                        <img src="assets/img/profiles/avatar-03.jpg" alt="Dreamstrip Testimonials"/>
                                    </div>
                                    <div className="people-name">
                                        <h5>Krishna</h5>
                                        <div className="review">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-content">
                                    <h5>Nice !!!</h5>
                                    <p>I've been in the printing business for decades, and I've used various platforms to sell equipment. Printing Machine Deal stands out for its specialized focus on our industry. The quality of leads and the professionalism of buyers have been exceptional.
                                    </p>
                                </div>
                                <div className="testi-location">
                                    <h6></h6>
                                    <p><i className="feather-map-pin"></i> Noida</p>
                                </div>
                            </div>
                            <div className="testi-widget-seven aos" data-aos="fade-up">
                                <div className="test-details">
                                    <div className="icon-blk">
                                        <img src="assets/img/profiles/avatar-04.jpg" alt="Dreamstrip Testimonials"/>
                                    </div>
                                    <div className="people-name">
                                        <h5>Rahul</h5>
                                        <div className="review">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testi-content">
                                    <h5>Wonderful!!!</h5>
                                    <p>Starting my own packaging business was challenging, but Printing Machine Deal played a pivotal role. I found high-quality, affordable machinery that allowed me to compete with established players. This platform is a game-changer."
                                    </p>
                                </div>
                                <div className="testi-location">
                                    <h6></h6>
                                    <p><i className="feather-map-pin"></i> Lucknow</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="blog-seven-section">
                <div className="container">
                    <div className="section-heading-two section-heading-seven text-center">
                        <div className="row">
                            <div className="col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                                <img src="assets/img/heading-logo-small.png" className="img-fluid" alt="title-img" />
                                <h2>From Our Blog</h2>
                                <p>Checkout what people are saying about our commuity businesses</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                                <div className="blog-seven grid-blog-seven aos" data-aos="fade-up">
                                    <div className="blog-image-seven">
                                        <a href="#"><img className="img-fluid" src="img/blog1.jpg" alt="Post Image" /></a>
                                        <div className="blog-date">
                                            <h4>09 <br></br><span>Sep</span> </h4>
                                        </div>
                                    </div>
                                    <div></div>
                                    <div className="blog-content-seven">
                                        <p className="blog-category-seven">
                                            <a href="javascript:void(0);"><span>New Machine</span></a>
                                        </p>
                                        <h3 className="blog-title">
                                            <a href="#">Printing and packaging machinery is our niche.
                                            </a>
                                        </h3>
                                        <p className="blog-description-seven">Printing and packaging machinery is our niche. Our platform is tailored specifically to this industry, ensuring that you find the machinery you need quickly and efficiently....
                                        </p>
                                        <ul className="entry-meta meta-item-seven">
                                            <li>
                                                <div className="post-author-seven">
                                                    <div className="post-author-img-seven">
                                                        <img src="assets/img/profiles/avatar-05.jpg" alt="Post Author" />
                                                    </div>
                                                    <a href="javascript:void(0);" className="mb-0"> <span> Admin </span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                                <div className="blog-seven grid-blog-seven aos" data-aos="fade-up">
                                    <div className="blog-image-seven">
                                        <a href="#"><img className="img-fluid" src="img/blog3.jpg" alt="Post Image" /></a>
                                        <div className="blog-date">
                                            <h4> 11 <br></br> <span>Aug</span></h4>
                                        </div>
                                    </div>
                                    <div></div>
                                    <div className="blog-content-seven">
                                        <p className="blog-category-seven">
                                            <a href="javascript:void(0);"><span>Old Machine</span></a>
                                        </p>
                                        <h3 className="blog-title">
                                            <a href="#">Wide range of options to suit your requirements.
                                            </a>
                                        </h3>
                                        <p className="blog-description-seven">Whether you're looking for offset printers, digital presses, binders, or any other equipment, our extensive listings offer a wide range of options to suit your requirements....
                                        </p>
                                        <ul className="entry-meta meta-item-seven">
                                            <li>
                                                <div className="post-author-seven">
                                                    <div className="post-author-img-seven">
                                                        <img src="assets/img/profiles/avatar-07.jpg" alt="Post Author" />
                                                    </div>
                                                    <a href="javascript:void(0);" className="mb-0"> <span> Admin </span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                                <div className="blog-seven grid-blog-seven aos" data-aos="fade-up">
                                    <div className="blog-image-seven">
                                        <a href="#"><img className="img-fluid" src="img/blog4.jpg" alt="Post Image" /></a>
                                        <div className="blog-date">
                                            <h4> 14 <br></br> <span>Mar</span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div></div>
                                    <div className="blog-content-seven">
                                        <p className="blog-category-seven">
                                            <a href="javascript:void(0);"><span>New Machine</span></a>
                                        </p>
                                        <h3 className="blog-title">
                                            <a href="blog-details.html">Printing Machine Deal isn't limited by borders
                                            </a>
                                        </h3>
                                        <p className="blog-description-seven">Printing Machine Deal isn't limited by borders or other things. We facilitate international connections, allowing you to access a global marketplace of machinery and equipment....
                                        </p>
                                        <ul className="entry-meta meta-item-seven">
                                            <li>
                                                <div className="post-author-seven">
                                                    <div className="post-author-img-seven">
                                                        <img src="assets/img/profiles/avatar-04.jpg" alt="Post Author" />
                                                    </div>
                                                    <a href="javascript:void(0);" className="mb-0"> <span> Admin </span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>

    );
}

export default Home;
